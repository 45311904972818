<template>
  <div class="picture-cropper">
    <cropper
      v-if="image"
      ref="cropper"
      class="picture-cropper__container"
      :src="image"
      :stencil-props="{
        aspectRatio: ratio
      }"
      @change="change"
    />
    <div class="card-footer">
      <button
        class="btn btn-sm btn-secondary"
        @click="close"
      >
        <i class="fas fa-times mr-2"></i>Annuler
      </button>
      <button
        class="btn btn-sm btn-success"
        @click="process"
      >
        <i class="fas fa-crop mr-2"></i>Valider
      </button>
    </div>
    <div class="picture-cropper__toolbar">
      <i
        class="fas fa-search-minus picture-cropper__toolbar--button"
        @click="zoomOut"
      ></i>
      <i
        class="fas fa-search-plus picture-cropper__toolbar--button"
        @click="zoomIn"
      ></i>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PictureCropperComponent',

  props: {
    picture: {
      type: [Object, Blob, String],
      default: () => {},
    },
    ratio: {
      type: [Number],
      default: 4 / 3,
    },
    format: {
      type: String,
      default: 'blob',
    },
  },

  data() {
    return {
      image: null,
      cropped: null,
    }
  },

  mounted() {
    this.image = this.picture && this.picture.original ? this.picture.original : this.picture;
  },

  methods: {
    change(value) {
      this.cropped = value.canvas;
    },
    zoomIn() {
      this.$refs.cropper.zoom(1.2);
    },
    zoomOut() {
      this.$refs.cropper.zoom(0.8);
    },
    process() {
      if (this.format === 'blob') {
        this.cropped.toBlob(blob => this.$emit('cropped', blob), 'image/jpeg');
      } else {
        this.$emit('cropped', this.cropped.toDataURL(this.format));
      }
    },
    close() {
      this.$emit('closed');
    },
  },
}
</script>

<style lang="scss">
@import '~vue-advanced-cropper/dist/style.css';
.picture-cropper {
  position: relative;
  width: 100%;
  height: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: stretch;
  border-radius: 10px;

  .card-footer {
    display: flex;
    justify-content: space-between;
    background: #ffffff;

    .btn {
      cursor: pointer;
      padding: 5px 10px;
      margin: 5px;
      background-color: #2d8c2d;
      color: #ffffff;

      &.btn-secondary {
        background-color: transparent;
        color: #000000;
      }
    }
  }

  &__container {
    width: 100%;
    flex: 1;
  }

  &__toolbar {
    position: absolute;
    top: 10px;
    left: 10px;
    color: #ffffff;

    &--button {
      cursor: pointer;
      font-size: 20px;

      & + & {
        margin-left: 10px;
      }
    }
  }
}
</style>
